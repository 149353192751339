import React from 'react';
import { ExtendCSS, Block, ThemePicker } from 'vcc-ui';
import { BackgroundColor, SpacingSize, IdParam } from './params';
import { DesktopSpacings } from './calculateSpacing';
import getSpacing from './getSpacing';

export type Params = Readonly<{
  topOuterSpacing?: SpacingSize;
  bottomOuterSpacing?: SpacingSize;
  topInnerSpacing?: SpacingSize;
  bottomInnerSpacing?: SpacingSize;
  background?: BackgroundColor;
}>;

type Props = Params &
  DesktopSpacings &
  IdParam & {
    extend?: ExtendCSS;
    marginsOnMobile?: boolean; // used on SectionContained
    children?: React.ReactNode;
    className?: string;
  };

const SectionCover = React.forwardRef<HTMLElement, Props>(
  (
    {
      topOuterSpacing = 'none',
      bottomOuterSpacing = 'none',
      topInnerSpacing = 'medium',
      bottomInnerSpacing = 'medium',
      background = 'white',
      topInnerSpacingDesktop,
      topOuterSpacingDesktop,
      bottomInnerSpacingDesktop,
      bottomOuterSpacingDesktop,
      children,
      extend,
      marginsOnMobile, // to remove this from `props`
      className,
      ...props
    },
    ref,
  ) => {
    return (
      <ThemePicker
        variant={['dark', 'black'].includes(background) ? 'dark' : 'light'}
      >
        <Block
          as="section"
          className={className}
          extend={[
            rootCSS({
              topOuterSpacing,
              bottomOuterSpacing,
              topInnerSpacing,
              bottomInnerSpacing,
              topInnerSpacingDesktop,
              topOuterSpacingDesktop,
              bottomInnerSpacingDesktop,
              bottomOuterSpacingDesktop,
              background,
            }),
            extend,
          ]}
          {...props}
          ref={ref}
        >
          {children}
        </Block>
      </ThemePicker>
    );
  },
);

SectionCover.displayName = 'SectionCover';

export default SectionCover;

const rootCSS =
  ({ background, ...params }: Required<Params> & DesktopSpacings): ExtendCSS =>
  ({ theme: { baselineGrid, color } }) => ({
    backgroundColor: {
      // We automatically switch to dark theme if background is `black` or `dark`
      white: color.background.primary,
      black: color.background.primary,
      light: color.background.secondary,
      dark: color.background.secondary,
    }[background],
    ...getSpacing({ baselineGrid, params }).untilM,

    fromM: getSpacing({ baselineGrid, params }).fromM,
  });
