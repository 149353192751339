// TODO: Fix eslint issues the next time this file is edited.
/* eslint-disable no-restricted-imports */
import React from 'react';
import FullscreenMessage from '@vcc-editorial/fullscreen-message';
import { GetStaticProps, InferGetStaticPropsType } from 'next';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useCurrentMarketSite } from '@vcc-www/market-sites';
import { Dictionaries } from '@volvo-cars/content-delivery-client';
import { getMarketSite } from '@volvo-cars/market-sites';
import { ExitPreview } from 'src/components/ExitPreview';
import { localDataContentClient } from 'src/content-management/contentClient';
import { useTranslate } from 'src/hooks';
import { DictionariesProvider } from 'src/providers';
import { logError } from 'src/utils/logError';
import { ErrorMessages } from '../src/constants/errorMessages';

export default function Custom404({
  dictionaries,
  preview,
  locale,
}: InferGetStaticPropsType<typeof getStaticProps> & {
  dictionaries: Dictionaries;
  preview: boolean;
  locale: string;
}) {
  return (
    <DictionariesProvider locale={locale} dictionaries={dictionaries}>
      <HeadContent preview={preview} />
      <Custom404Body />
      {preview && <ExitPreview />}
    </DictionariesProvider>
  );
}

const Custom404Body = (): JSX.Element => {
  const translate = useTranslate();
  const marketSite = useCurrentMarketSite();
  return (
    <FullscreenMessage
      content={{
        message:
          translate('global.404Page.message') ||
          'We couldn’t find the page you’re looking for.',
        imageLandscape: {
          src: '/static/dealers/media/fullscreen-hero-landscape.jpg',
          alt: '',
        },
        imagePortrait: {
          src: '/static/dealers/media/fullscreen-hero-portrait.jpg',
          alt: '',
        },
        cta: {
          text: translate('global.404Page.ctaLabel') || 'Visit the homepage',
          href: marketSite.href,
        },
      }}
      background="black"
    />
  );
};

// Always local dictionaries
// Will almost never be used since [...seoKeyword] handles most of the 404 cases
export const getStaticProps: GetStaticProps<{
  dictionaries: Dictionaries;
  preview: boolean;
  locale: string;
}> = async (props) => {
  const siteSlug = 'intl';
  const { locale } = getMarketSite(siteSlug);
  const dictionariesFetch = localDataContentClient.getDictionary(
    'global.404Page',
    {
      locale,
      environment: props.preview ? 'authoringPreview' : 'live',
    },
  );
  let dictionaries: Dictionaries = {};
  try {
    dictionaries = await dictionariesFetch;
  } catch (error) {
    logError(`${ErrorMessages.API_DICTIONARY_FETCH_ERROR}: ${error}`);
  }
  return {
    props: {
      dictionaries,
      preview: !!props.preview,
      locale,
    },
    revalidate: 60,
  };
};

type HeadContentProps = { preview: boolean };
const HeadContent = ({ preview }: HeadContentProps): JSX.Element => {
  const { asPath } = useRouter();
  const translate = useTranslate();
  const previewText = preview ? '[PREVIEW] ' : '';
  const message =
    translate('global.404Page.message') ||
    'We couldn’t find the page you’re looking for.';
  return (
    <>
      <Head>
        <title>{`${previewText} ${message}`}</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta name="description" content={message} />
        <link rel="canonical" href={`https://www.volvocars.com${asPath}`} />
        <meta name="og:url" content={`https://www.volvocars.com${asPath}`} />
        <meta name="og:title" content={message} />
        <meta name="og:description" content={message} />
      </Head>
    </>
  );
};
