import React from 'react';
import { ExtendCSS, Flex, Text, View } from 'vcc-ui';
import { Image_DEPRECATED } from '@vcc-package/media/DEPRECATED';
import SectionCover from '@vcc-www/page-section/SectionCover';
import { Button } from '@vcc-www/buttons';
import {
  topbarHeightUntilM,
  topbarHeightFromM,
} from '@volvo-cars/site-nav-embed';
import FullscreenMessageProps from './FullscreenMessage.props';

const FullscreenMessage: React.FC<FullscreenMessageProps> = ({
  content: { message, cta, imageLandscape, imagePortrait },
  topInnerSpacing = 'none',
  bottomInnerSpacing = 'none',
  background = 'black',
  ...params
}) => (
  <SectionCover
    topInnerSpacing={topInnerSpacing}
    bottomInnerSpacing={bottomInnerSpacing}
    background={background}
    {...params}
  >
    <Flex extend={wrapperCSS}>
      <Image_DEPRECATED
        loading="eager"
        objectFit="cover"
        extend={imageCSS}
        sources={{
          default: imageLandscape.src,
          untilL: imagePortrait.src,
        }}
        alt={imageLandscape ? imageLandscape.alt : ''}
      />
      <View extend={contentWrapperCSS} spacing={6}>
        <Text variant="cook" extend={messageCSS}>
          {message}
        </Text>
        {cta?.text && cta.href && (
          <Flex extend={ctaWrapperCSS}>
            <Button
              intent="secondary"
              variant="default"
              href={cta.href}
              target={cta.target}
              title={cta.title}
            >
              {cta.text}
            </Button>
          </Flex>
        )}
      </View>
    </Flex>
  </SectionCover>
);

export default FullscreenMessage;

const wrapperCSS: ExtendCSS = {
  position: 'relative',
  justifyContent: 'center',
  flexDirection: 'row',
  minHeight: `calc(100vh - ${topbarHeightUntilM}px)`,
  fromM: {
    minHeight: `calc(100vh - ${topbarHeightFromM}px)`,
  },
};

const contentWrapperCSS: ExtendCSS = ({ theme: { baselineGrid } }) => ({
  position: 'relative',
  flexDirection: 'column',
  alignSelf: 'stretch',
  textAlign: 'center',
  alignItems: 'center',
  maxWidth: 544,
  width: `calc(100% - ${baselineGrid * 6}px)`,
  margin: `0 ${baselineGrid * 3}px`,
  zIndex: 10,
  padding: `${baselineGrid * 14}px 0`,
  fromXL: {
    padding: `${baselineGrid * 24}px 0`,
  },
});

const messageCSS: ExtendCSS = {
  maxWidth: '100%',
};

const ctaWrapperCSS: ExtendCSS = {
  alignSelf: 'center',
};

const imageCSS: ExtendCSS = {
  position: 'absolute',
  left: 0,
  top: 0,
  height: '100%',
  width: '100%',
  zIndex: 5,
};
